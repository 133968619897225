import React from 'react';

import { useGlobalState } from '../global/global';

import '../styles/page404.css';
import Layout from '../components/layout';

const Page404 = props => {
    const [state, dispatch] = useGlobalState();

    const setImageSrc = () => {
        if (state.lightThemeState) {
            return '/images/light/404.png'
        }

        if (!state.lightThemeState) {
            return '/images/dark/404.png'
        }
    }

    return (
        <Layout location={props.location}>
            <div className='page404'>
                <img src={setImageSrc()} className='page404__image' alt='404 image' />
            </div>
        </Layout>
    );
}

export default Page404